/* eslint-disable radix */
import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { AbstractControl, FormControl } from '@angular/forms'
import * as moment from 'moment'

import ChangeCountryService from '../country-change-service'
import SSnService from '../ssn-change-service'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export default class DatePickerComponent implements AfterViewInit, OnInit {
  @Input() controlName: string
  @Input() country: string
  @Input() dateControl: AbstractControl = new FormControl()

  @Input() inputTitle: string
  @Input() errorMessage: string
  @Input() errorMessageExpired: string
  @Input() errorMessageAge?: string
  @Input() checkIfExpiredMethod: (date: Date) => boolean
  @Input() checkIfAgeIsValid?: (date: Date) => boolean

  inputField: HTMLInputElement
  shouldShowHelperText: boolean
  previousInputValue: string
  inputIsInFocus: boolean

  constructor(private changeCountryService: ChangeCountryService, private ssnService: SSnService) {}

  ngOnInit(): void {
    this.changeCountryService.newSelectedIssuingCountry.subscribe((res) => {
      this.country = res.countryCode
      this.formatDateOnLanguageChange()
    })
    this.changeCountryService.newSelectedCountry.subscribe((res) => {
      this.country = res.countryCode
      this.formatDateOnLanguageChange()
    })
    if (this.controlName === 'licenseBirthDate') {
      this.ssnService.newSsn.subscribe((date) => {
        const formattedDate = date.toDateString()
        this.updateDate(formattedDate)
      })
    }
    this.ssnService.autoFillEvent.subscribe(({ control, controlName }) => {
      if (controlName === this.controlName) {
        this.updateDate(control.value)
      }
    })
  }

  ngAfterViewInit(): void {
    this.inputField = document.getElementById(this.controlName) as HTMLInputElement
    if (this.dateControl.value) {
      this.updateDate(this.dateControl.value)
    }
    this.previousInputValue = this.inputField?.value
  }

  handleInputOnFocus(): void {
    this.inputIsInFocus = true
    this.showHelperText()
  }

  showHelperText(): void {
    if (
      this.inputIsInFocus &&
      !this.dateControl.touched &&
      !this.dateControl.value &&
      !this.dateControl.errors?.wrongFormat &&
      !this.dateControl.errors?.expired
    ) {
      this.shouldShowHelperText = true
    }
  }

  countryFIOrNO(): boolean {
    return this.country.toUpperCase() === 'FI' || this.country.toUpperCase() === 'NO'
  }

  handleInputWhileWriting(event: any): void {
    this.showHelperText()
    let inputValue = this.inputField.value

    // Removing the backslash if deleting white space in front of it
    if (/\D\/$/.test(inputValue)) {
      inputValue = inputValue.substring(0, inputValue.length - 2)
    }
    const dateValues = this.createDateGroups(inputValue)

    if (event.inputType !== 'deleteContentBackward') {
      this.insertFormattedDateInInputField(dateValues)
    } else {
      if (/\D\/$/.test(this.previousInputValue.substring(0, this.previousInputValue.length - 1))) {
        this.previousInputValue = this.previousInputValue.substring(0, this.previousInputValue.length - 2)
      }

      if (this.previousInputValue?.substring(0, this.previousInputValue.length - 1) === inputValue) {
        this.inputField.value = inputValue
        this.validateDate(dateValues)
      } else {
        this.shouldShowHelperText = false
        this.inputField.value = ''
      }
    }
    this.validateDate(dateValues)
    this.previousInputValue = this.inputField.value
  }

  handleInputOnBlur(event: any) {
    this.inputIsInFocus = false

    this.showHelperText()

    const dateValues = this.createDateGroups(event.target.value)

    if (!this.countryFIOrNO() && dateValues[2]?.length < 2) {
      if (dateValues[2] !== '0') {
        dateValues[2] = `0${dateValues[2]}`
      }
    }

    if (this.countryFIOrNO()) {
      event.target.value =
        this.addMonthOrDate(dateValues[0], 31) +
        this.addMonthOrDate(dateValues[1], 12) +
        this.addYear(dateValues[2], true)
    } else {
      event.target.value =
        this.addYear(dateValues[0]) +
        this.addMonthOrDate(dateValues[1], 12) +
        this.addMonthOrDate(dateValues[2], 31, true)
    }

    this.validateDate(dateValues)
    this.shouldShowHelperText = false
  }

  private insertFormattedDateInInputField(dateValues: string[]): void {
    if (this.countryFIOrNO()) {
      this.inputField.value =
        this.addMonthOrDate(dateValues[0], 31) +
        this.addMonthOrDate(dateValues[1], 12) +
        this.addYear(dateValues[2], true)
    } else {
      this.inputField.value =
        this.addYear(dateValues[0]) +
        this.addMonthOrDate(dateValues[1], 12) +
        this.addMonthOrDate(dateValues[2], 31, true)
    }
  }

  private formatDateOnLanguageChange() {
    const dateValues = this.createDateGroupsOnCountryChange(this.inputField.value)
    let formattedDateValues: string

    if (this.countryFIOrNO() && dateValues[2]?.length === 2) {
      formattedDateValues =
        this.addMonthOrDate(dateValues[2], 31) +
        this.addMonthOrDate(dateValues[1], 12) +
        this.addYear(dateValues[0], true)
      this.inputField.value = formattedDateValues
    } else if (!this.countryFIOrNO() && dateValues[2]?.length === 4) {
      formattedDateValues =
        this.addYear(dateValues[2]) +
        this.addMonthOrDate(dateValues[1], 12) +
        this.addMonthOrDate(dateValues[0], 31, true)
      this.inputField.value = formattedDateValues
    }

    if (formattedDateValues) {
      this.validateDate(this.createDateGroupsOnCountryChange(formattedDateValues))
    } else {
      this.validateDate(dateValues)
    }
    this.previousInputValue = this.inputField.value
  }

  private createDateGroups(input: string) {
    const inputValues = input.split('/').map((group) => group.replace(/\D/g, ''))

    if (this.countryFIOrNO()) {
      if (inputValues[0]?.length > 2) {
        inputValues[1] = inputValues[0].substring(2, 3)
        inputValues[0] = inputValues[0].substring(0, 2)
      }
      if (inputValues[1]?.length > 2) {
        inputValues[2] = inputValues[1].substring(2, 3)
        inputValues[1] = inputValues[1].substring(0, 2)
      }
    } else {
      if (inputValues[0]?.length > 4) {
        inputValues[1] = inputValues[0].substring(4, 5)
        inputValues[0] = inputValues[0].substring(0, 4)
      }
      if (inputValues[1]?.length > 2) {
        inputValues[2] = inputValues[1].substring(2, 3)
        inputValues[1] = inputValues[1].substring(0, 2)
      }
    }

    return inputValues
  }

  private createDateGroupsOnCountryChange(input: string) {
    const inputValues = input.split('/').map((group) => group.replace(/\D/g, ''))
    return inputValues
  }

  private addYear(input: string, last: boolean = false) {
    if (/^([0-9]+)$/.test(input)) {
      if (input.length > 4) {
        input = input.substring(0, 4)
      }

      return input.length === 4 && !last ? `${input} / ` : input.replace(/\D/g, '')
    }
    return ''
  }

  private addMonthOrDate(input: string, max: number, last: boolean = false) {
    if (/^([0-9]+)$/.test(input)) {
      if (input.length > 2) {
        input = input.substring(0, 2)
      }

      const formattedDate = this.getFormattedValue(input, max)
      return formattedDate.length === 2 && !last ? `${formattedDate} / ` : formattedDate.replace(/\D/g, '')
    }
    return ''
  }

  private getFormattedValue(input: string, max: number): string {
    const num = parseInt(input)
    return num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? `0${num}` : input
  }

  private getTrimmedValues(values: string[]): string[] {
    if (values[2]) {
      if (this.countryFIOrNO()) {
        values[2] = values[2].substring(0, 4)
      } else {
        values[2] = values[2].substring(0, 2)
      }
    }
    return values
  }

  private isDateCorrectLength(values: string[]): boolean {
    if (this.countryFIOrNO()) {
      return values[2]?.length >= 4 && values[1]?.length === 2
    }

    return values[2]?.length >= 2 && values[1]?.length === 2
  }

  /**
   * Event handler for the licenseIssueDate input
   * Makes sure that licenseIssueDate is a valid date and isn't a future date
   */
  private validateDate(date: string[]): void {
    let validDate = false
    let formattedDate: Date

    if (this.isDateCorrectLength(date)) {
      date = this.getTrimmedValues(date)

      if (this.countryFIOrNO() && date[2]?.length >= 4) {
        const b = `${date[0]}-${date[1]}-${date[2]}`
        const m = moment(b, 'DD-MM-YYYY')

        formattedDate = new Date(parseInt(date[2].substring(0, 4)), parseInt(date[1]) - 1, parseInt(date[0]))

        if (m.isValid()) {
          validDate = true
        }
      } else if (!this.countryFIOrNO() && date[2]?.length >= 2) {
        const b = `${date[0]}-${date[1]}-${date[2]}`
        const m = moment(b, 'YYYY-MM-DD')

        formattedDate = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))

        if (m.isValid()) {
          validDate = true
        }
      }
    }

    if (date[0].length > 0) {
      if (validDate) {
        this.dateControl.setValue(formattedDate, { emitModelToViewChange: false })

        if (formattedDate instanceof Date && !Number.isNaN(formattedDate.getTime())) {
          if (this.checkIfExpiredMethod(formattedDate)) {
            this.setError('expired')
          }
          if (this.checkIfAgeIsValid && !this.checkIfAgeIsValid(formattedDate)) {
            this.setError('age')
          }
        } else {
          this.setError('wrongFormat')
        }
      } else {
        this.setError('wrongFormat')
      }
    } else {
      this.setError('wrongFormat')
    }
  }

  private setError(type: 'wrongFormat' | 'expired' | 'age') {
    this.dateControl.setErrors({
      [type]: true,
    })
    if (this.dateControl.errors?.wrongFormat && this.dateControl.touched) {
      this.shouldShowHelperText = false
    }
  }

  private updateDate(date: string): void {
    const newDate = new Date(date)
    let formattedDate: string

    const day = this.getFillerDate(newDate.getDate().toString())
    const month = this.getFillerDate((newDate.getMonth() + 1).toString())

    if (day !== 'NaN' && month !== 'NaN' && !Number.isNaN(newDate.getFullYear())) {
      if (this.countryFIOrNO()) {
        formattedDate = `${day} / ${month} / ${newDate.getFullYear()}`
      } else {
        formattedDate = `${newDate.getFullYear()} / ${month} / ${day}`
      }
    } else {
      formattedDate = ''
    }

    this.inputField.value = formattedDate
    this.dateControl.setValue(newDate, { emitModelToViewChange: false })
  }

  private getFillerDate(date: string) {
    return date.length < 2 ? `0${date}` : date
  }
}
