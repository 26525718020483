<label
  class="pure-material-textfield-outlined"
  [ngClass]="{
    'pure-material-textfield-outlined-error': dateControl.invalid && dateControl.touched
  }"
>
  <input
    [formControl]="dateControl"
    id="{{ controlName }}"
    placeholder=" "
    type="text"
    (focus)="handleInputOnFocus()"
    (blur)="handleInputOnBlur($event)"
    (input)="handleInputWhileWriting($event)"
  />
  <span>{{ inputTitle | translate }}</span>
</label>

<p class="leftPadding form__helper" *ngIf="shouldShowHelperText">
  {{ countryFIOrNO() ? 'DD / MM / YYYY' : 'YYYY / MM / DD' }}
</p>

<p
  class="leftPadding form__error"
  *ngIf="
    (dateControl.errors?.wrongFormat === true || (!dateControl.value && inputIsInFocus)) &&
    dateControl.touched &&
    !shouldShowHelperText
  "
>
  {{ countryFIOrNO() ? 'DD / MM / YYYY' : 'YYYY / MM / DD' }}
</p>

<p
  class="form__error leftPadding"
  *ngIf="dateControl.invalid && dateControl.touched && !dateControl.value && !shouldShowHelperText && !inputIsInFocus"
>
  {{ errorMessage | translate }}
</p>

<p
  class="form__error leftPadding"
  *ngIf="dateControl.invalid && dateControl.touched && dateControl.errors?.expired === true && !shouldShowHelperText"
>
  {{ errorMessageExpired | translate }}
</p>

<p
  class="form__error leftPadding"
  *ngIf="dateControl.invalid && dateControl.touched && dateControl.errors?.age === true && !shouldShowHelperText"
>
  {{ errorMessageAge | translate }}
</p>
