<div class="flex-row sectionTitle">
  <span class="sectionHeading">{{ 'customer-details' | translate }}</span>
  <span (click)="navigateToDriverDetails()" class="btn__edit"></span>
</div>

<div class="flex-column">
  <div class="flex-row">
    <div class="section__label">
      {{ 'name' | translate }}
    </div>
    <div class="information-label" *ngIf="customer">{{ customer.firstName }} {{ customer.lastName }}</div>
  </div>

  <div class="flex-row">
    <div class="section__label" *ngIf="!sweden()">
      {{ 'driving-license' | translate }}
    </div>

    <div class="section__label" *ngIf="sweden()">
      {{ 'number' | translate }}
    </div>

    <div class="information-label" *ngIf="customer">
      {{ licenseNumber }}
    </div>
  </div>

  <div class="flex-row" *ngIf="!driverCountryAndLicenseCountryFIFI()">
    <div class="section__label">
      {{ 'due-date' | translate }}
    </div>
    <div class="information-label" *ngIf="customer">
      {{ customer?.driverLicense.licenseExpireDate | date: 'mediumDate' }}
    </div>
  </div>
</div>
